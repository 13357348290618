import { PlayersType } from "../../types";
export const playersData: PlayersType[] = [
  {
    id: 1,
    firstName: "Olawale",
    lastName: "Ayejuyone",
    position: "PG",
    height: "6'0",
    weight: "157 lbs",
    history: [
      {
        dob: "11-07-1997",
        nickName: "The Solution",
        countryOfOrigin: "Nigeria",
        experience: 7,
      },
    ],
  },
  {
    id: 2,
    firstName: "Alexander",
    lastName: "Odita",
    position: "PF",
    height: "6'1",
    weight: "192 lbs",
    history: [
      {
        dob: "05-03-1996",
        nickName: "OG",
        countryOfOrigin: "Nigeria",
        experience: 7,
      },
    ],
  },
  {
    id: 3,
    firstName: "Ayodeji",
    lastName: "Ibikunle",
    position: "SG",
    height: "5'9",
    weight: "194 lbs",
    history: [
      {
        dob: "05-03-1995",
        nickName: "Akure",
        countryOfOrigin: "Nigeria",
        experience: 3,
      },
    ],
  },
  {
    id: 4,
    firstName: "Eneyeme",
    lastName: "Adekoye",
    position: "SF",
    height: "5'11",
    weight: "225 lbs",
    history: [
      {
        dob: "09-29-1995",
        nickName: "Yeme naw",
        countryOfOrigin: "Nigeria",
        experience: 10,
      },
    ],
  },
  {
    id: 5,
    firstName: "Steve",
    lastName: "Liadi",
    position: "SG",
    height: "5'9",
    weight: "180 lbs",
    history: [
      {
        dob: "05-06-1985",
        nickName: "Ona (The way)",
        countryOfOrigin: "USA",
        experience: 22,
      },
    ],
  },
  {
    id: 6,
    firstName: "William",
    lastName: "Champion",
    position: "PF",
    height: "6'1",
    weight: "280 lbs",
    history: [
      {
        dob: "05-03-1996",
        nickName: "Champ",
        countryOfOrigin: "Nigeria",
        experience: 7,
      },
    ],
  },
  {
    id: 7,
    firstName: "Dariel",
    lastName: "Soriano",
    position: "SG",
    height: "5'11",
    weight: "192 lbs",
    history: [
      {
        dob: "05-03-1996",
        nickName: "OG",
        countryOfOrigin: "Nigeria",
        experience: 7,
      },
    ],
  },
  {
    id: 8,
    firstName: "Samson",
    lastName: "Olabimpe",
    position: "---",
    height: "6'2",
    weight: "---",
    history: [
      {
        dob: "---",
        nickName: "---",
        countryOfOrigin: "---",
        experience: 0,
      },
    ],
  },
  {
    id: 9,
    firstName: "Obinna",
    lastName: "Ekweremuba",
    position: "---",
    height: "6'2",
    weight: "---",
    history: [
      {
        dob: "---",
        nickName: "---",
        countryOfOrigin: "---",
        experience: 0,
      },
    ],
  },
  {
    id: 10,
    firstName: "Ifeanyi",
    lastName: "Ekweremuba",
    position: "---",
    height: "6'2",
    weight: "---",
    history: [
      {
        dob: "---",
        nickName: "---",
        countryOfOrigin: "---",
        experience: 0,
      },
    ],
  },
  {
    id: 11,
    firstName: "Fuad",
    lastName: "Bakare",
    position: "---",
    height: "6'2",
    weight: "---",
    history: [
      {
        dob: "---",
        nickName: "---",
        countryOfOrigin: "---",
        experience: 0,
      },
    ],
  },
  {
    id: 12,
    firstName: "Iman",
    lastName: "Tesleem",
    position: "PG",
    height: "5'9",
    weight: "165",
    history: [
      {
        dob: "03-23-2001",
        nickName: "---",
        countryOfOrigin: "Nigeria",
        experience: 6,
      },
    ],
  },
  {
    id: 13,
    firstName: "Seyi",
    lastName: "Adekoye",
    position: "---",
    height: "---",
    weight: "---",
    history: [
      {
        dob: "---",
        nickName: "---",
        countryOfOrigin: "---",
        experience: 0,
      },
    ],
  },
  {
    id: 14,
    firstName: "Quam",
    lastName: "---",
    position: "---",
    height: "---",
    weight: "---",
    history: [
      {
        dob: "---",
        nickName: "---",
        countryOfOrigin: "---",
        experience: 0,
      },
    ],
  },
  {
    id: 15,
    firstName: "Rilwan",
    lastName: "Bakare",
    position: "---",
    height: "---",
    weight: "---",
    history: [
      {
        dob: "---",
        nickName: "---",
        countryOfOrigin: "Nigeria",
        experience: 0,
      },
    ],
  },
  {
    id: 16,
    firstName: "Adolo",
    lastName: "Aneni",
    position: "---",
    height: "---",
    weight: "---",
    history: [
      {
        dob: "---",
        nickName: "---",
        countryOfOrigin: "---",
        experience: 0,
      },
    ],
  },
  {
    id: 17,
    firstName: "Timi",
    lastName: "Olufoye",
    position: "---",
    height: "---",
    weight: "---",
    history: [
      {
        dob: "---",
        nickName: "---",
        countryOfOrigin: "---",
        experience: 0,
      },
    ],
  },
  {
    id: 18,
    firstName: "Toyosi",
    lastName: "Adekoye",
    position: "---",
    height: "---",
    weight: "---",
    history: [
      {
        dob: "---",
        nickName: "---",
        countryOfOrigin: "---",
        experience: 0,
      },
    ],
  },
  {
    id: 19,
    firstName: "Adewale",
    lastName: "Adekoye",
    position: "---",
    height: "---",
    weight: "---",
    history: [
      {
        dob: "---",
        nickName: "---",
        countryOfOrigin: "---",
        experience: 0,
      },
    ],
  },
];
